import './survey_question_step_component.css'
import { Controller } from "@hotwired/stimulus"
import { jsRequestHeaders, isTruthy, isFalsy } from '@javascript/javascripts/utils'
import axios from 'axios'
import lottie from 'lottie-web-light'

export default class extends Controller {

  static values = { messages: Object }
  static targets = ['charsCountHint', 'messagePlaceholder', 'submitButton']

  initialize() {
    this.form = this.element;
    this.carousel = document.querySelector('[data-carousel]')

    this.isQuizzMode = isTruthy(this.form.dataset.isQuizz)
    this.isDisabled = isTruthy(this.form.dataset.disabled)
    this.lastStep = this.form.dataset.lastStep
    this.surveyID = this.form.dataset.surveyId

    this.nextMessageLength =  this.form.dataset.nextMessage?.length || 0
    this.successMessageLength =  this.form.dataset.successMessage?.length || 0
    this.errorMessageLength =  this.form.dataset.errorMessage?.length || 0

    if(this.isDisabled){
      this._disableForm();
    }
    if(!this.lastStep && this.hasSubmitButtonTarget){
      [...this.form.querySelectorAll('input[type="text"], textarea')].map(input => this._handleSubmitButtonState(input))
    }
  }

  submitForm(event){
    if(event.target.nodeName === 'BUTTON' || event.target.nodeName === 'FORM'){
      event.preventDefault();
    }

    let messageLength = 0;
    let attribute = this.form.getAttribute('action');
    let stats;
    if (window.self !== window.top) {
      attribute = attribute.replace('/surveys/', '/lms/surveys/') + '?jwt=' + window.localStorage.getItem('jwt')
      stats = JSON.parse(window.localStorage.getItem('quizStats-' + this.surveyID)) || { total: 0, correct: 0, status: 'unknown' }
    }

    axios.post(attribute, new FormData(this.form), jsRequestHeaders).then(response => {

      if(this.isQuizzMode){
        const expected = response.data.question.quizz.answers.sort()
        const actual = Array.from(this.form.querySelectorAll('input:checked')).map((i) => parseInt(i.value, 10)).sort()
        
        if (window.self !== window.top) {
          stats.total += 1
          stats.status = 'unknown'
        }

        if(expected.equals(actual)){
          messageLength = this.successMessageLength
          this._showMessage('correct')
          if (window.self !== window.top) {
            stats.correct += 1
          }
        } else {
          messageLength = this.errorMessageLength
          this._showMessage('incorrect')
        }
        expected.forEach((id) => {
          this.form.querySelector(`.choices label[for="q_${this.form.dataset.questionId}_c_${id}"]`).classList.add('correct')
        })
      } else {
        if(!response.data['participation_skipped']){
          messageLength = this.nextMessageLength
          this._showMessage('success')
        }
      }
      if (window.self !== window.top) {
        window.localStorage.setItem('quizStats-' + this.surveyID, JSON.stringify(stats))
      }
    })
    .catch((error) => {
      if(error.response.data.participation_errors){
        Object.values(error.response.data.participation_errors).forEach(message => {
          messageLength = message.length
          this._showMessage('warning', message)
        })
      }
    })
    .finally(() => {
      this._carouselSlideNext(messageLength, this.isQuizzMode)
    });
  }

  toggleSubmitButton(event){
    const input = event.target;
    this._handleSubmitButtonState(input)
  }

  countChars(event){
    const textarea = event.target;
    const charsLimit = parseInt(textarea.getAttribute('data-ccl'), 10);
    const charsCount = textarea.value.length

    if(charsCount > 0){
      if(charsCount >= charsLimit) { textarea.value = textarea.value.substring(0, charsLimit) }
      this.charsCountHintTarget.innerHTML = ` (${textarea.value.length}/${charsLimit})`
    } else {
      this.charsCountHintTarget.innerHTML = null
    }
  }

  _disableForm(){
    this.form.dataset.disabled = true
    this.form.action = "#"
    Array.from(this.form.querySelectorAll('input,select')).map(input => input.setAttribute('disabled', 'disabled'))
  }

  _carouselSlideNext(messageLength, isQuizzMode){
    let event = new CustomEvent('slide-next')
    event.messageLength = messageLength
    event.isQuizzMode = isQuizzMode
    this.carousel.dispatchEvent(event)
  }

  _handleSubmitButtonState(input){
    0 === input.value.length ? this._disableSubmitButton() : this._enableSubmitButton()
  }

  _disableSubmitButton(){
    this.submitButtonTarget.classList.add('btn--disabled')
    this.submitButtonTarget.disabled = 'disabled'
  }

  _enableSubmitButton(){
    this.submitButtonTarget.classList.remove('btn--disabled')
    this.submitButtonTarget.removeAttribute('disabled')
  }

  _showMessage(type, body){
    const props = this.messagesValue[type]
    this.messagePlaceholderTarget.innerText = ''

    const animation = document.createElement('span')
    animation.setAttribute('class', props.animation.styles)
    this.messagePlaceholderTarget.appendChild(animation)
    this._buildLottie(animation, props.animation.name, props.animation.speed)

    const text = document.createElement('span')
    if(body || props.text.body)
      text.innerText = body || props.text.body
    if(props.text.styles)
      text.setAttribute('class', props.text.styles)
    this.messagePlaceholderTarget.appendChild(text)
  }

  _buildLottie(container, name, speed){
    import(`@vendor/javascripts/lotties/${name}.json`).then(json => {
      const args = {
        container: container,
        animationData: json.default,
        renderer: 'svg',
        loop: false,
        autoplay: true
      }
      const instance = lottie.loadAnimation(args);
      if(speed)
        instance.setSpeed(speed)
    })
  }

  _getMessage(type){
    return this[type + 'MessageTarget']
  }

  _resetMessageAnimation(type){
    this[type + 'MessageTarget'].classList.add('hidden')
    if(this[type + 'MessageTarget'].querySelector('lottie-player')){
      this[type + 'MessageTarget'].querySelector('lottie-player').getLottie().goToAndPlay(0)
    }
  }
}
