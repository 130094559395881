const JUNE_API_URL = process.env.JUNE_API_URL
const JUNE_WRITE_KEY = process.env.JUNE_WRITE_KEY

const track = async (userId, accountId, event, properties = {}) => {
  const data = {
    userId: userId.toString(),
    event,
    properties,
    context: {
      groupId: accountId.toString()
    }
  }

  return makeRequest('track', data);
};

async function makeRequest(route, data) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Basic ${JUNE_WRITE_KEY}`
      },
      body: JSON.stringify(data), 
    }; 

    const response = await fetch(`${JUNE_API_URL}/${route}`, requestOptions);

    if (!response.ok) {
      return null;
    }
    
    return response.json();
  } catch (error) {
    return null;
  }
}

export {
  track
}