import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {

  static get targets() {
  }

  connect(){
  }

  initialize() {
  }

  resetProgression(event) {
    event.preventDefault();

    const surveyId = document.querySelector('input[name="surveyId"]').value;
    const participantId = document.querySelector('input[name="participantId"]').value;
    const surveyHashId = document.querySelector('input[name="surveyHashId"]').value;
    const locale = document.querySelector('input[name="locale"]').value;

    return axios({
      method: 'post',
      url: Env.api_url + "/app-ruby/reset-progression",
      data: {
        surveyId,
        participantId,
      },
      headers: {'accept': 'application/javascript'}
    })
    .then(() => {
      window.location.pathname.includes('/surveys') ? window.location.reload() : window.location.href = locale + '/surveys/' + surveyHashId;
    })
  }
}
