import { Controller } from "@hotwired/stimulus"
import { isInViewport, trackAction, getElementHeight } from '@javascript/javascripts/utils'

export default class extends Controller {

  static get targets() {
    return ['articleBody', 'indicator', 'indicatorHolder']
  }

  initialize() {
    this.options = this.data.get('options') ? JSON.parse(this.data.get('options')) : {}

    this.handleExternalLinks();
    if(this.options.tracking){
      this.handleTracking(this.options.tracking)
    }
    if(this.options.indicator){
      this.handleIndicator()
    }
  }

  handleExternalLinks(){
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }

  handleIndicator(){
    window.addEventListener('scroll', () => {
      if(isInViewport(this.indicatorHolderTarget)){
        this.indicatorTarget.classList.remove('sticky')
        this.indicatorTarget.classList.add('absolute', 'hidden')
      } else {
        this.indicatorTarget.classList.remove('absolute', 'hidden')
        this.indicatorTarget.classList.add('sticky')
      }
    });
  }

  handleTracking(options){
    // let indicator = wrapper.querySelector('[data-reading-indicator]');
    let cache = {};

    const isVisible = (elem) => {
      var bounding = elem.getBoundingClientRect();
      return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    window.addEventListener('scroll', () => {
      let completion = 0;
      let documentHeight = (window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop);
      let windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
      let windowBottom = (documentHeight) + windowHeight;
      let elementTop = this.articleBodyTarget.offsetTop;
      completion = Math.floor((windowBottom - elementTop) / getElementHeight(this.articleBodyTarget) * 100);
      completion = completion < 0 ? 0 : completion > 100 ? 100 : completion;

      if(this.options.indicator){
        this.indicatorTarget.style.width = completion + '%';
      }

      if(completion % 25 == 0 && cache[`completion_${options.scope}_${completion}`] == null){
        trackAction('articleview', `${options.scope}/completion/${completion}`)
        cache[`completion_${options.scope}_${completion}`] = 1;
      }
    });
  }

  // connect() {
  //   console.log('coucou coucou');
  //   console.log(playaTarget)
  //
  //
  //   // console.log(document.querySelectorAll('[data-video-player]'))

  //   // console.log(document.getElementById('foo'))
  //
  // }
}
